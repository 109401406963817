import React, { useEffect, useState } from 'react';
import { getUserLocationAndClendlyUrl } from '../utils/consultationApis';

const User = React.createContext();

export const UserProvider = ({ children }) => {

  const [user, setUser] = useState({
    phoneCountry: 'UnitedStates',
    phoneNumber: '',
  });
  const [consultation, setConsultation] = useState({
    consultationButtonName: "Get Consultation",
    consultationUrl: ""
  });

  const handleSetUser = (userInfo) => {
    setUser({
      ...user,
      ...userInfo
    })
  }

  const setUserEligibilityForFreeConsultation = async () => {
    getUserLocationAndClendlyUrl()
      .then(response => {
        const res = response.data.output;
        setConsultation(prev => ({
          ...prev,
          consultationUrl: res.calendlyURL,
          consultationButtonName: res.value
        }))
      })
  }

  useEffect(() => {
    setUserEligibilityForFreeConsultation();
  }, [])

  return (
    <User.Provider value={{ user, setUser: handleSetUser, consultation }}>
      {children}
    </User.Provider>
  );
};

export const useUser = () => React.useContext(User);

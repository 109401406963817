import { makeStyles } from '@material-ui/core';
import React from 'react';
import company1 from '../../assets/logo/partnerCompanies/company1.svg';
import company10 from '../../assets/logo/partnerCompanies/company10.svg';
import company12 from '../../assets/logo/partnerCompanies/company12.svg';
import company13 from '../../assets/logo/partnerCompanies/company13.svg';
import company14 from '../../assets/logo/partnerCompanies/company14.svg';
import company2 from '../../assets/logo/partnerCompanies/company2.svg';
import company3 from '../../assets/logo/partnerCompanies/company3.svg';
import company4 from '../../assets/logo/partnerCompanies/company4.svg';
import company5 from '../../assets/logo/partnerCompanies/company5.svg';
import company6 from '../../assets/logo/partnerCompanies/company6.svg';
import company7 from '../../assets/logo/partnerCompanies/company7.svg';
import company8 from '../../assets/logo/partnerCompanies/company8.svg';
import company9 from '../../assets/logo/partnerCompanies/company9.svg';
import Marquee from "react-fast-marquee";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            margin: '2rem 0 0 0',
            padding: '0 2rem',
        },
        textAlign: 'center',
        margin: '5rem 0 0 0',
        padding: '0 5rem',
        '& h1': {
            margin: '0 0 2rem 0'
        },
        '& p': {
            margin: '0 0 2rem 0'
        }
    },
    networkCompany: {
        margin: '0 2rem'
    },
    companiesLogo: {
        width: '100%',
    },
    primaryColorText: {
        color: '#315CD5'
    }
}))

const InfoSection5 = () => {

    const classes = useStyles();

    const partnerCompaniesLogo = [
        [company1, company2, company3, company4, company5, company6, company7],
        [company8, company9, company10, company12, company13, company14],
    ];

    return (
        <div className={classes.root}>
            <h1>Access our network of +1500 companies to find a job</h1>
            <p>With interviewhelp.io, <span className={classes.primaryColorText}>you get accompanied</span> until you get an offer. Once you are ready, we help you line up interviews thanks to our extensive network.</p>
            <div>
                {partnerCompaniesLogo.map((companies, index) => {
                    return (
                        <Marquee
                            key={'companies-Logo' + index}
                            speed={50}
                            gradientWidth={10}
                        >
                            {companies.map((logo, index) => {
                                return (
                                    <div key={'company' + index} className={classes.networkCompany}>
                                        <img className={classes.companiesLogo} src={logo} />
                                    </div>
                                )
                            })}
                        </Marquee>
                    )
                })}
            </div>
        </div>
    )
}

export default InfoSection5;
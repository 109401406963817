import { MuiThemeProvider } from "@material-ui/core";
import React, { lazy, Suspense, useEffect, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import ReactGa from "react-ga";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Components/footer1";
import NavBar from "./Components/NavBar";
import { UserProvider } from "./context/UserProvider";
import Home from './Pages/HomePage/index';
import theme from "./themes/primary";
import { SnackbarProvider } from 'notistack';

const Faq = lazy(() => import('./Pages/Faq'));
const TracksUpdated = lazy(() => import('./Pages/TracksUpdated'));
const AboutUs = lazy(() => import('./Pages/About-Us'));
const InterviewSDE = lazy(() => import('./Pages/InterviewSDE'));
const InterviewSDM = lazy(() => import('./Pages/InterviewSDM'));
const InterviewTPM = lazy(() => import('./Pages/InterviewTPM'));
const BehavioralCoaching = lazy(() => import('./Pages/Behavioral-Coaching'));
const SystemDesign = lazy(() => import('./Pages/SystemDesign'));
const SDMCore = lazy(() => import('./Pages/SDMCore'));
const TPMCore = lazy(() => import('./Pages/TPMCore'));
const TrackDetails = lazy(() => import('./Pages/TracksPage/index'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const ContactUs = lazy(() => import('./Pages/Contact-Us'));
const Tracks = lazy(() => import('./Pages/Tracks'));
const MockInterview = lazy(() => import('./Pages/MockInterview'));
const JobsPage = lazy(() => import('./Pages/JobsPage/index'));
const IndexingJobDetailsPage = lazy(() => import('./Pages/JobsPage/IndexingJobDetailsPage'));
const Feedback = lazy(() => import('./Pages/Feedback'));
const Page404 = lazy(() => import('./Pages/Page404'));
const FaangInterviews = lazy(() => import('./Pages/FaangInterviews'));
const Faqs = lazy(() => import('./Components/Disclaimer/Faqs'));
const PrivacyPolicy = lazy(() => import('./Components/Disclaimer/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./Components/Disclaimer/Terms&Conditions'));
const InterviewHelpJobsPage = lazy(() => import('./Pages/Jobs'));

// import HowItWorks from './Pages/HowItWorks';

function App() {
  const [focusForm, setFocusForm] = useState(false);
  const [email, setEmail] = useState(null);
  const [calendlyPrefillData, setCalendlyPrefillData] = useState(null);

  useEffect(() => {
    ReactGa.initialize("UA-152900192-1", "auto");
    //to report page view
    ReactGa.pageview(window.location.pathname);
  }, []);

  const setFocusOfUserForm = () => {
    setFocusForm(true);
  };

  const resetFocusOfUserForm = () => {
    setFocusForm(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <UserProvider>
          <Router>
            <NavBar
              setFocusOfUserForm={setFocusOfUserForm}
              focusForm={focusForm}
              resetFocusForm={resetFocusOfUserForm}
              setEmail={(e) => setEmail(e)}
              email={email}
              calendlyPrefillData={calendlyPrefillData}
              setCalendlyPrefillData={setCalendlyPrefillData}
            />
            <Route
              path="/"
              render={({ location }) => {
                window.gtag("config", "UA-152900192-1", {
                  page_path: location.pathname + location.search,
                });
              }}
            />
            <main>
              <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
                <Switch>
                  <Route
                    path="/"
                    render={() => (
                      <Home
                        focusForm={focusForm}
                        resetFocusForm={resetFocusOfUserForm}
                        setFocusOfUserForm={setFocusOfUserForm}
                      />
                      // <Home
                      //   focusForm={focusForm}
                      //   resetFocusForm={resetFocusOfUserForm}
                      //   setEmail={(e) => setEmail(e)}
                      //   calendlyPrefillData={calendlyPrefillData}
                      //   setCalendlyPrefillData={setCalendlyPrefillData}
                      // />
                    )}
                    exact
                  />
                  {/* <Route path='/employer' component={HowItWorks} /> */}
                  <Route path="/faq" component={Faq} />
                  <Route path="/track-updated/:trackname" component={TracksUpdated} />
                  <Route path="/about-us" component={AboutUs} />
                  <Route
                    path="/contact-us"
                    render={() => (
                      <ContactUs
                        focusForm={focusForm}
                        resetFocusForm={resetFocusOfUserForm}
                        setEmail={(e) => setEmail(e)}
                      />
                    )}
                  />
                  <Route path="/interview-sde" component={InterviewSDE} />
                  <Route path="/interview-sdm" component={InterviewSDM} />
                  <Route path="/interview-tpm" component={InterviewTPM} />
                  <Route path="/behavioral-coaching" component={BehavioralCoaching} />
                  <Route path="/system-design" component={SystemDesign} />
                  <Route path="/sdm-core" component={SDMCore} />
                  <Route path="/tpm-core" component={TPMCore} />
                  <Route path="/faang-interviews" component={FaangInterviews} />
                  <Route
                    path="/thankyou"
                    render={() => (
                      <Thankyou email={email} resetEmail={() => setEmail(null)} />
                    )}
                  />
                  <Route path="/track/:trackname" component={TrackDetails} />
                  <Route path="/products" component={Tracks} />
                  <Route path="/mock-interview" component={MockInterview} />
                  <Route path="/faqs" component={Faqs} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route path="/terms-and-condition" component={TermsConditions} />
                  <Route path="/interviewhelp-jobs" component={InterviewHelpJobsPage} exact />
                  <Route path="/partner-jobs" component={JobsPage} exact />
                  <Route path="/partner-jobs/:jobId" component={IndexingJobDetailsPage} exact />
                  <Route path="/feedback" component={Feedback} />
                  <Route component={Page404} /> {/**404 route */}
                </Switch>
              </Suspense>
            </main>
            <Footer />
          </Router>
        </UserProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;

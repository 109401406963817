import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import fangCompanies1 from '../../assets/faang/fangCompanies1.png';
import fangCompanies2 from '../../assets/faang/fangCompanies2.png';
import fangCompaniesMdScreens from '../../assets/faang/fangCompaniesMdScreens.png';
import fangCompaniesSmScreens from '../../assets/faang/fangCompaniesSmScreens.png';
import { useUser } from "../../context/UserProvider";
import { useState } from "react";
import LoginModal from "../../Components/LoginModal";
import axiosInstance from "../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: '80%',
            justifyContent: 'center',
        },
        [theme.breakpoints.down("xs")]: {
            padding: '2rem 0 2rem 0',
        },
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 4fr 1fr',
        background: "#315CD5",
        color: '#FFF',
        boxSizing: 'border-box',
        marginTop: '64px',
        padding: '5rem 0 2rem 0',
    },
    contentDiv: {
        textAlign: 'center',
        position: 'relative',
        '& p': {
            [theme.breakpoints.down("md")]: {
                fontSize: '0.8rem',
                letterSpacing: '0.02rem',
            },
            fontSize: '1.2rem',
            margin: '0 0 3rem 0',
        },
        '& h1': {
            [theme.breakpoints.down("md")]: {
                fontSize: '2rem',
                letterSpacing: '0.2rem',
            },
            [theme.breakpoints.down("sm")]: {
                letterSpacing: '0.1rem',
            },
            fontSize: '2.5rem',
            letterSpacing: '0.4rem',
            margin: '0 0 3rem 0',
        },
    },
    getFreeConsultaionContainer: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            maxWidth: '80%',
            gap: '1rem',
            margin: '0 auto 3rem',
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: '100%',
        },
        background: "#466CD9",
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        gap: '2rem',
        borderRadius: '20px',
        justifyContent: 'center',
        maxWidth: '70%',
        margin: '0 auto 6rem',
        '& input': {
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
            padding: '1rem',
            background: '#FFF',
            borderRadius: '7px',
            height: '3rem',
            width: '60%',
            borderColor: 'transparent',
            '&:focus': {
                outline: 'none',
            }
        },
        '& button': {
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
            textTransform: 'none',
            background: '#171717',
            padding: '1rem',
            borderRadius: '8px',
            color: '#FFF',
            height: '3rem',
            '&:hover': {
                background: '#030303'
            }
        }
    },
    bottomInfo: {
        // [theme.breakpoints.down("xs")]: {
        //     position: 'absolute',
        //     bottom: '4rem',
        //     right: '0',
        //     left: '0',
        // },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '3rem',
        '& span': {
            position: 'relative',
            top: '-2px',
            marginLeft: '0.5rem',
        },
        '& svg': {
            width: '1.2rem !important',
            height: 'auto',
        }
    },
    conpaniesLogo1: {
        [theme.breakpoints.down("md")]: {
            display: 'none'
        },
        position: 'relative',
        '& img': {
            width: '10rem',
            position: 'absolute',
            bottom: '-2rem',
            right: '0',
        }
    },
    conpaniesLogo2: {
        [theme.breakpoints.down("md")]: {
            display: 'none'
        },
        position: 'relative',
        '& img': {
            width: '10rem',
            position: 'absolute',
            bottom: '-2rem',
            left: '0',
        }
    },
    mdScreenLogoCompanies: {
        [theme.breakpoints.down("md")]: {
            display: 'block'
        },
        [theme.breakpoints.down("xs")]: {
            display: 'none'
        },
        display: 'none',
        margin: '0 0 3rem 0',
        '& img': {
            width: '100%',
        }
    },
    smScreenLogoCompanies: {
        [theme.breakpoints.down("xs")]: {
            display: 'block'
        },
        display: 'none',
        margin: '0 0 3rem 0',
        '& img': {
            width: '80%',
        }
    },

}))

const Header = ({ focusForm, resetFocusForm }) => {

    const classes = useStyles();
    const emailBoxRef = useRef();
    const headerTop = useRef();
    const { consultation } = useUser();
    const [openConsultationModal, setOpenConsultationModal] = useState(false);
    const [email, setEmail] = useState();

    useEffect(() => {
        if (focusForm) {
            headerTop.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            emailBoxRef.current.classList.add('transition-with-shadow');
            emailBoxRef.current.focus();
            setTimeout(() => {
                emailBoxRef.current.classList.remove("transition-with-shadow");
            }, 2000);
            resetFocusForm();
        }
    }, [focusForm]); // eslint-disable-line

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        axiosInstance.post("users", { email })
        setEmail(email);
        setOpenConsultationModal(true);
    }

    return (
        <div className={classes.root} ref={headerTop}>
            <div className={classes.mdScreenLogoCompanies}>
                <img src={fangCompaniesMdScreens} />
            </div>
            <div className={classes.conpaniesLogo1}>
                <img src={fangCompanies1} />
            </div>
            <div className={classes.contentDiv}>
                <h1>Your co-pilot to land your dream job at a FAANG company</h1>
                <p>Get the support and connections you need to break into the competitive world of Facebook, Amazon, Apple, Netflix and Google.</p>
                <form className={classes.getFreeConsultaionContainer} onSubmit={handleSubmit}>
                    <input placeholder='Enter your email*' type='email' required ref={emailBoxRef} />
                    <Button type='submit'>{consultation.consultationButtonName}</Button>
                </form>
                <div className={classes.smScreenLogoCompanies}>
                    <img src={fangCompaniesSmScreens} />
                </div>
                <div className={classes.bottomInfo}>
                    <div>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>Free consultation.</span>
                    </div>
                </div>
            </div>
            <div className={classes.conpaniesLogo2}>
                <img src={fangCompanies2} />
            </div>
            <LoginModal
                open={openConsultationModal}
                handleClose={() => { setOpenConsultationModal(false) }}
                focusForm={true}
                resetFocusForm={() => { }}
                email={email}
                setEmail={(e) => { }}
                isCalendlyModal={false}
                calendlyPrefillData={{}}
                setCalendlyPrefillData={() => { }}
            />
        </div>
    )
}

export default Header;
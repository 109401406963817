import { faFacebook, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import Logo from "../assets/logo/whiteLogo.svg";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      // margin: '2rem 0 0 0',
      padding: '2rem',
    },
    // margin: '5rem 0 0 0',
    padding: '3rem',
    background: "#315CD5"
  },
  footerRow: {
    paddingBottom: '2rem',
    borderBottom: '1px solid rgb(255, 255, 255, 0.3)'
  },
  interviewHelpLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  directLinks: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    paddingTop: '2rem',
    '& p': {
      color: '#ADD8E6',
      margin: '1em 0 1em 0',
    },
    '& ul': {
      padding: 0,
      listStyleType: 'none',
    },
    '& li': {
      margin: '1em 0 1em 0',
      '& a': {
        textDecoration: 'none',
        cursor: 'pointer',
        color: '#FFF',
      }
    },
    '& > div': {
      [theme.breakpoints.down("xs")]: {
        margin: 'auto',
      },
      width: '100%',
      maxWidth: '25rem',
    }
  },
  collapsibleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      margin: '0'
    },
    '& svg': {
      [theme.breakpoints.down("xs")]: {
        display: 'block',
        cursor: 'pointer',
      },
      fontSize: '1rem',
      color: '#ADD8E6',
      display: 'none',
    }
  },
  copyrightSection: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column-reverse',
    },
    paddingBottom: '0',
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    '& svg': {
      color: '#FFF',
      fontSize: '1.5rem !important',
      opacity: '0.7',
    },
    '& p': {
      color: '#FFF',
      opacity: '0.7',
      margin: 0,
    },
    '& > div': {
      [theme.breakpoints.down("xs")]: {
        alignItems: 'start',
        gap: '1rem',
        flexDirection: 'column',
      },
      '& a:nth-child(1)': {
        marginLeft: 0,
      },
      display: 'flex',
      gap: '1.5rem',
      alignItems: 'center',
    }
  }
}))

const urls = [
  ['COMPANY', [{ name: 'About us', link: '/about-us' }, { name: 'Privacy Policy', link: '/privacy-policy' }, { name: 'Terms & conditions', link: '/terms-and-condition' }, { name: 'FAQ', link: '/faqs' }, { name: 'Contact us', link: '/contact-us' }]],
  ['COACHING', [{ name: 'Mock interview', link: '/mock-interview' }, { name: '1-1 coaching', link: '/mock-interview' }, { name: 'Resume review', link: '' }]],
  ['CAREERS', [{ name: 'Technical Program Manager', link: '/track/technical-program-manager' }, { name: 'Software Development Manager', link: '/track/software-engineering-manager' }, { name: 'Software Development Engineer', link: '/track/software-development-engineer' }]],
  ['CLASSES', [{ name: 'Advanced coding', link: 'https://www.landingpages.interviewhelp.io/advanced-coding-classes', newWeb: true }, { name: 'Distributed System Design', link: 'https://www.landingpages.interviewhelp.io/system-design', newWeb: true }]],
  ['POPULAR RESOURCES', [
    { name: 'Interview question bank', link: 'https://airtable.com/shr4FVRyqSPZvwXFo/tblk4ksZVz2AaTtJP', newWeb: true }, 
    { name: 'What to expect: Solution Architect Interview for Amazon and Google', link: 'https://www.interviewhelp.io/blog/solution-architect-interview-for-amazon-and-google/', newWeb: true }, 
    { name: 'FAANG software engineer salary', link: 'https://www.interviewhelp.io/blog/tags/faang-salary/', newWeb: true }, 
    { name: 'Leetcode 904 - Fruit Into Baskets', link: 'https://www.interviewhelp.io/blog/posts/leetcode-904-fruit-into-baskets/', newWeb: true 
  }]],
]

const Footer = () => {

  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [visibleLinks, setVisibleLinks] = useState({});

  const toggleVisibleLink = (index) => setVisibleLinks((prev) => ({ ...prev, [index]: !prev[index] }));

  const openAllLinks = () => {
    const openedLinks = {};
    urls.map((val, index) => {
      openedLinks[index] = true;
    });
    setVisibleLinks(openAllLinks);
  }

  useEffect(() => {
    if (isSmallScreen) {
      setVisibleLinks({ 0: true });
    }
  }, [isSmallScreen])

  return (
    <div className={classes.root} id="website-footer">
      <div className={classes.interviewHelpLogo + ' ' + classes.footerRow}>
        <span>
          <a href="/">
            <img alt="" src={Logo}></img>
          </a>
        </span>
        <span>
          <Typography
            key="InterviewHelp"
            component="a"
            className="headerTitle"
            href="/"
          >
            InterviewHelp
          </Typography>
        </span>
      </div>
      <div className={classes.directLinks + ' ' + classes.footerRow}>
        {
          urls.map((links, idx) => {
            return (
              <div key={links[0] + idx}>
                <div className={classes.collapsibleContainer} onClick={() => toggleVisibleLink(idx)}>
                  <p>{links[0]}</p>
                  {visibleLinks[idx] ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
                {(visibleLinks[idx] || !isSmallScreen) && <ul>
                  {links[1].map((link, index) => (
                    <li key={link + index}>
                      {
                        link['newWeb'] ? <a target={'_blank'} href={link['link']}>{link['name']}</a>
                          : <a href={link['link']} >{link['name']}</a>
                      }
                    </li>
                  ))}
                </ul>}
              </div>
            )
          })
        }
      </div>
      <div className={classes.footerRow + ' ' + classes.copyrightSection} style={{ borderBottom: 'none' }}>
        <p>© 2023 by InterviewHelp</p>
        <div>
          <div>
            <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/channel/UCmdo8WoDIwDM9BMhXs9sOrw" className="youtube social">
              <FontAwesomeIcon icon={faYoutube} size="3x" />
            </a>
            <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/techinterview2" className="twitter social">
              <FontAwesomeIcon icon={faTwitter} size="3x" />
            </a>
            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/interviewhelpio/" className="facebook social">
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            <a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/company/interviewhelp-io" className="linkedin social">
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </a>
          </div>
          <p>8015 Douglas Ave SE, Snoqualmie</p>
          <p>Privacy</p>
          <p>Terms</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
import { makeStyles } from "@material-ui/core";
import React from "react";
import FaqComponent from "../../RedesignedComponents/FaqComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            margin: '2rem 0 0 0',
            padding: '2rem',
        },
        textAlign: 'center',
        margin: '5rem 0 0 0',
        padding: '5rem',
        background: '#F3F6FC',
        '& h1': {
            margin: '0 0 2rem 0'
        },
        '& h5': {
            lineHeight: '1.4',
            fontSize: '0.95rem',
            textAlign: 'start',
            margin: '0'
        },
    },
    mainContentWidth: {
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        width: '50rem',
        margin: 'auto',
    }
}))

const FAQs = () => {

    const classes = useStyles();
    const faqs = [
        {
            question: 'What about your coaching is different than learning on my own?', answer: 'There are three reasons you should use interviewhelp.'
            , pointers: [
                "Our proven strategy and results reduce risk, as we have helped individuals transition from low to mid-tier companies to top tech companies.",
                "Regular classes and assessments keep you motivated and on track, while also holding you accountable and keeping you on your toes.",
                "We provide personalized coaching to help you overcome blockers and improve your interview performance. Our analysis helps identify the root cause of any issues and enables us to work together to find solutions."
            ]
        },
        { question: 'How are the coaching sessions held?', answer: 'Coaching sessions are held on zoom. They are usually 1 hour long.' },
        { question: 'How much does it cost?', answer: 'We have plans where you pay after your succeed ( income share agreements) and also if you want to go transactional. The pricing varies from candidate to candidate.' },
    ]


    return (
        <div className={classes.root}>
            <h1>FAQ</h1>
            <div className={classes.mainContentWidth}>
                <FaqComponent faqs={faqs} />
            </div>
        </div>
    )
}

export default FAQs;
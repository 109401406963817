import React from 'react';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

import Swagatika from '../../assets/ClientsImages/Swagatika.jpg';
import adisheshu from '../../assets/ClientsImages/adisheshu.jpg';
import azi from '../../assets/ClientsImages/azi.jpeg';
import bharathRam from '../../assets/ClientsImages/bharathRam.jpg';
import george from '../../assets/ClientsImages/george.jpg';
import harshvir from '../../assets/ClientsImages/harshvir.jpeg';
import mayuresh from '../../assets/ClientsImages/mayuresh.jpeg';
import temitayo from '../../assets/ClientsImages/temitayo.jpeg';
import notAvilable from '../../assets/ClientsImages/userImageNotAvilable.png';

import TestimonialsDisplayer from '../../Components/Tracks/TestimonialsDisplayer';
import facebook from "../../assets/coach/facebook.png";
import wayfair from "../../assets/coach/wayfair.png";
import axonLogo from '../../assets/logo/AXON.png';
import microsoftLogo from '../../assets/logo/Microsoft.png';
import oracleLogo from '../../assets/logo/Oracle.png';
import amazonLogo from '../../assets/logo/amazon_ColorFull_logo.png';
import antworks from '../../assets/logo/antworks.png';
import google from '../../assets/logo/google.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            margin: '2rem auto 0 auto',
        },
        margin: '5rem auto 0 auto',
    }
}))

const data = [
    {
        currCompany: facebook,
        body: "“Bharat is a seasoned TPM who has very impressive credentials. His prior positions included TPM at companies like Linkedin, Amazon, and Oracle. However, his previous experience with Facebook and Google did not yield positive results. The failure intrigued him, and he was determined to understand and grow into his dream company. Our training provided him a structure for answering various behavior and technical questions. We also taught him techniques to market himself in an interview setting. He worked very hard on the concepts he took from the numerous 1-on-1 sessions with his coaches. The offer from Facebook and a nice 30% increase in total comp as a result of 3-4 months of dedicated effort on his part and the guidance from the interview help team”",
        clientLogo: bharathRam,
        expertName: 'Bharat Ram',
        expertDesignation: 'Technical Program Manager',
        videoPath: "https://player.vimeo.com/video/368678931",
    },
    {
        currCompany: facebook,
        body: "“Sai, Joined interview help as a candidate in 2020. He spent around 6 months with us in our data structures and algorithms course. This is his journey in his own words.”",
        clientLogo: notAvilable,
        expertName: 'Sai Charan',
        expertDesignation: 'Sr. Software Engineer',
        videoPath: "https://player.vimeo.com/video/724463787",
    },
    {
        currCompany: amazonLogo,
        body: "“Azi was having a hard time clearing phone screens. Azi has 8+ years of experience as a software developer with companies like Zillow and Ad Colony. He had applied to a lot of companies, but things were not moving past the phone screens. He contacted interviewhelp.io in late November. As we wanted quick results, I advised Azi to join both my leetcode easy and leetcode medium programs and also gave him some homework problems. The first few sessions made it clear to us that scala was getting in his way to execute, so he moved to Java. With a 2-3 hours regular and guided practice, he was able to land a full-time job offer in < 17 working days”",
        clientLogo: azi,
        expertName: 'Azi Crawford',
        expertDesignation: 'Software Development Engineer',
        videoPath: "https://player.vimeo.com/video/382682201",
    },
    {
        currCompany: amazonLogo,
        body: "“Adiseshu was working in Infosys and was looking to move to a FAANG company when he came across our service. He had made a couple of attempts at Amazon and had not been able to clear the interview. The frustrating part was that he did not get any feedback, so he hit a roadblock. Interviewhelp team worked with improving his system design skills, polishing his TPM skills, and working on his answer delivery. We helped him with choosing the right stories and gave him critical feedback on delivery. Now Adi is working at Amazon and is one of the top tier companies in the industry”",
        clientLogo: adisheshu,
        expertName: 'Adiseshu',
        expertDesignation: 'Sr. Technical Program Manager',
        videoPath: "https://player.vimeo.com/video/368678435",
    },
    {
        currCompany: amazonLogo,
        body: "“Swagatika,came across interviewhelp as she was trying to figure out distributed system design.She had reported multiple failures and had feedback from various interviews around system design. Having not worked in the field she had accumulated a lot of theoritical knowledge from the books and her own reading. During our first session it was clear to me that all she needed was a structure and course correction on some newbie mistakes.”",
        clientLogo: Swagatika,
        expertName: 'Swagatika',
        expertDesignation: 'Technical Program Manager',
        videoPath: "https://player.vimeo.com/video/409568677",
    },
    {
        currCompany: google,
        body: "“I just accepted an offer from Google! InterviewHelp was a big factor in helping me get there. The traditional advice is to interview with multiple companies at once, and while this is helpful when it comes to offer negotiation, you usually don't get detailed feedback beyond a binary yes/no. With InterviewHelp, you actually have a mock interview session with experienced professionals (often in FAANGs) who will be doing the actual interviewing. At the end of the mock interview, your interviewer goes through your interview performance in detail to help you improve. For me personally, my interviewers gave me confidence in my preparation highlighting my interview strengths. They then proceeded to give me feedback on where I can improve and giving me some suggestions there along the way. I highly recommend signing up for this!”",
        clientLogo: notAvilable,
        expertName: 'Ahmed',
        expertDesignation: 'Software Development Manager',
        videoPath: null,
    },
    {
        currCompany: microsoftLogo,
        body: "“TemiTayo's main issue was that she was not able to think and interact at the same time. She was looking for a realtime environment where she can get practice. Interviewhelp's unique process provided her that interactive environment, and she was able to clear Microsofts technical bar”",
        clientLogo: temitayo,
        expertName: 'TemiTayo',
        expertDesignation: 'Software Development Engineer - 1',
        videoPath: "https://player.vimeo.com/video/369372735",
    },
    {
        currCompany: oracleLogo,
        body: "“InterviewHelp exceeded my expectations. We had 2-hour classes three times per week. Each class we focused on a specific topic (e.g. binary search trees, DP, system design). For each topic we went through fundamentals on how respective data structures/algorithms work, practiced solving example problems and systemized the approach. Before InterviewHelp I couldn’t pass the on-site tech interview, after - I passed the FAANG on-site interview with flying colors and accepted an offer from my dream company.”",
        clientLogo: notAvilable,
        expertName: 'Alex',
        expertDesignation: 'Sr. Software Engineer',
        videoPath: null,
    },
    {
        currCompany: oracleLogo,
        body: '“Mayuresh was in a very precarious state. The startup he was working in was folding, and all the top-level executives were jumping ships. When he joined our program, he was very nervous and not sure if things will pan out for him. He was on an F1 visa and desperately needed H1B sponsorship. My team helped him get very very regular with leetcode, conducted multiple mock interviews to find the gaps, and coached him on quickly filling them up. When he was ready interviewhelp team presented him to a potential top tier (tier-1 and tier-2 ) employers. Mayuresh was able to crack the oracle interview in his first attempt. He was so happy with the Oracle team and offer that he declined other interviews we had lined up for him”',
        clientLogo: mayuresh,
        expertName: 'Mayuresh Raul',
        expertDesignation: 'Software Development Engineer - 2',
        videoPath: "https://player.vimeo.com/video/380126934",
    },
    {
        currCompany: axonLogo,
        body: '“Harhvir was clueless about the new LEET code interview movement that everyone has so fondly adopted. Fortunately, a friend referred him to interviewhelp, and he started with our unique daily leetcode program. Within one month, he was able to master the essential leetcode pattern and now has a great fulltime job at AXON”',
        clientLogo: harshvir,
        expertName: 'Harsh Vir',
        expertDesignation: 'Sr. Software Development Engineer',
        videoPath: "https://player.vimeo.com/video/372079077",
    },
    {
        currCompany: wayfair,
        body: "“George Andraws is a seasoned Dev manager(SAP Concur), QA Manager (Apple), and TPM (Amazon) and has 19+ years of industry experience. He was working at SAP Concur as a Sr. SDM and was looking to change. He quickly realized that he was solid at people and project management but not so in Distributed System Design. His various roles did not give him as much opportunity to flex his Distributed System Design skills. Interviewhelp.io's team helped him structure his approach to system design interviews. After a few sessions, George quickly picked up the skill and was able to get two offers”",
        clientLogo: george,
        expertName: 'George Andraws',
        expertDesignation: 'Sr. Software Development Manager',
        videoPath: "https://player.vimeo.com/video/378664920",
    },
    {
        currCompany: antworks,
        body: "“After ten years of working at the same place, it is but natural that you lose touch with the latest and greatest in the interview world. The work of figuring out the various bars at various organizations, preparing, networking, and still keep everything in balance is not easy. The colossal effort required to change jobs makes many people stick to their current positions. Praveen was in this same situation a few months ago. After getting frustrated going through countless youtube videos, he finally took Interviewhelp's help and was able to land a job as a Director of Technical Program Management at AntWorks”",
        clientLogo: notAvilable,
        expertName: 'Praveen',
        expertDesignation: 'Director',
        videoPath: "https://player.vimeo.com/video/382377963",
    },
]

const Testimonial = () => {

    const classes = useStyles();

    return (
        <TestimonialsDisplayer
            data={data}
            className={classes.root}
            title={'100% of our clients were successfully placed'}
        />
    )
}

export default Testimonial;

export const removeTrackSpaces = (trackname) => {
  if (!trackname) return trackname;
  trackname = trackname.toLowerCase().replaceAll(" ", "-");
  return trackname;
}

export const hasChildren = (item) => {
  const { subMenu: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export const getPageSizeForSwiperSlides = (isSMScreen, isMDScreen, totelElements) => {
  if (isSMScreen) {
    return totelElements > 1 ? 1 : totelElements;
  } else if (isMDScreen) {
    return totelElements > 2 ? 2 : totelElements;
  } else {
    return totelElements > 3 ? 3 : totelElements;
  }
}

export const moveToProvidedRef = (ref, offset) => {
  offset = offset ? offset : 0;
  const topPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: topPosition - offset,
    behavior: 'smooth',
  });
}

export function convertToGoogleJobsJSON(inputJobs) {
  if (!inputJobs) {
    return {}
  }
  const googleJobsJSON = inputJobs.map((job) => {
    return {
      "@context": "http://schema.org",
      "@type": "JobPosting",
      "title": job.jobTitle,
      "description": job.jobDescription,
      "identifier": {
        "@type": "PropertyValue",
        "name": "Company",
        "value": job.company
      },
      "datePosted": new Date(job.jobPostingDateAndTime).toISOString(),
      // "validThrough": new Date(job.jobPostingDate).toISOString(), // Adjust this date based on your requirements.
      "employmentType": job.employmentType, // Need to send from backend
      "hiringOrganization": {
        "@type": "Organization",
        "name": job.company,
        "sameAs": job.jobLink
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": job.jobLocation
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": "USD",
        "value": {
          "@type": "QuantitativeValue",
          "value": job.baseSalary,
        }
      }
    };
  });

  return googleJobsJSON;
}
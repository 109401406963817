import { makeStyles } from '@material-ui/core';
import React from 'react';
import coachGroup from '../../assets/coach/coachGroup.png';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            margin: '2rem 0 0 0',
            padding: '0 2rem',
        },
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '1rem',
        margin: '5rem 0 0 0',
        padding: '0 5rem',
        '& div': {
            flex: '1 1 0',
        }
    },
    imageWithText: {
        display: 'flex',
        alignItems: 'baseline',
        gap: '0.5rem',
        '& h1': {
            [theme.breakpoints.down("xs")]: {
                fontSize: '3rem',
            },
            color: '#FFB8C0',
            fontSize: '6rem',
            display: 'inline-block',
            margin: '0',
        },
        '& span': {
        }
    },
    responsiveImg: {
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        },
        maxWidth: '30rem'
    },
    textAlignCenter: {
        textAlign: 'center',
    }
}))

const InfoSection1 = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <h1>Connect with vetted experts in your field</h1>
                <div className={classes.imageWithText}>
                    <h1>+150</h1>
                    <div>
                        <span><b>Network experts</b></span>
                    </div>
                </div>
                <div className={classes.imageWithText}>
                    <h1>+15</h1>
                    <div>
                        <span><b>Years of experience of all mentors in top tech companies</b></span>
                    </div>
                </div>
            </div>
            <div className={classes.textAlignCenter}>
                <img src={coachGroup} className={classes.responsiveImg} />
            </div>
        </div>
    )
}

export default InfoSection1;
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import interviewQuestions from '../../assets/Banner/interviewQuestions2.png';
import arrowRightUpIcon from "../../assets/track-icon/info-icons/arrow-up-right.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            margin: '2rem 0 0 0',
            padding: '0 2rem',
        },
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '1rem',
        margin: '5rem 0 0 0',
        padding: '0 5rem',
        '& > div': {
            flex: '1 1 0',
        }
    },
    responsiveImg: {
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        },
        maxWidth: '30rem'
    },
    mainContent: {
        '& h1': {
            fontSize: '2rem',
            margin: 0,
        },
        '& li': {
            margin: '0.5rem 0'
        }
    },
    tabLists: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 'unset',
        gap: '0.5rem',
        '& a': {
            textDecoration: 'none',
            border: "1px solid #C1CEF2",
            borderRadius: '8px',
            padding: '12px 20px',
            boxSizing: 'border-box',
            color: 'black',
            '&:hover': {
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            }
        },
        '& img': {
            width: '1rem',
            marginBottom: '-3px',
        }
    },
    textAlignCenter: {
        textAlign: 'center',
    }
}))

const InfoSection3 = (props) => {

    const classes = useStyles();

    const tracks = [
        {
            href: "/track/technical-program-manager",
            title: 'About TPM'
        },
        {
            href: "/track/software-engineering-manager",
            title: 'About SDM'
        },
        {
            href: "/track/software-development-engineer",
            title: 'About SDE'
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.mainContent}>
                <h1>Access exclusive resources to develop your skills</h1>
                <p>After helping thousands of students, we have built the methods and tools that work best:</p>
                <ul>
                    <li>Bots to help you learn data structures, algorithms and system design concepts.</li>
                    <li>Live classes with experts to learn efficiently.</li>
                </ul>
                <p><b>We have three FAANG Tracks:</b></p>
                <div className={classes.tabLists}>
                    {tracks.map((val, idx) => (
                        <Link key={idx + val.title} to={val.href}>
                            {val.title}&nbsp;&nbsp;
                            <img src={arrowRightUpIcon} />
                        </Link>
                    ))}
                </div>
            </div>
            <div className={classes.textAlignCenter}>
                <img src={interviewQuestions} className={classes.responsiveImg} />
            </div>
        </div>
    )
}

export default InfoSection3;
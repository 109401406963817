import { makeStyles } from "@material-ui/core";
import dashIcon from '../assets/track-icon/info-icons/dash-icon-black.svg';
import plusIcon from '../assets/track-icon/info-icons/plus-icon-black.svg';
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
    faqContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '1rem',
        margin: 'auto',
        padding: '1rem 2rem',
        borderTop: '1px solid #C9D4F3',
        '& p': {
            textAlign: 'start'
        },
        '& ol': {
            textAlign: 'start',
            '& li': {
                margin: '0.5rem 0'
            }
        }
    },
    faqIcon: {
        width: '0.8rem',
        position: 'relative',
    },
    hyphenIcon: { bottom: '-0.5rem', },
    plusIcon: { bottom: '-0.2rem', },
    cursorPointer: {
        cursor: 'pointer',
    },
    contentBox: {
        flexGrow: 1
    },
    questionBox: {
        paddingBottom: '10px',
        boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px',
    },  
    answerContainer: {
        maxHeight: '20rem',
        overflow: 'auto',
    },
    answer: {
        opacity: '0',
        animation: `$animateAnswer 500ms ${theme.transitions.easing.easeIn} forwards`,
    },
    '@keyframes animateAnswer': {
        'to': {
            opacity: 1,
        }
    }
}))

const FaqComponent = ({
    faqs,
    scrollClass
}) => {

    const classes = useStyles();
    const [openedFaqs, setOpenedFaqs] = useState({ 0: true });
    const toggleFaq = (index) => setOpenedFaqs((prev) => ({ ...prev, [index]: !prev[index] }))

    return (
        <>
            {faqs.map((val, index) => (
                <div
                    className={classes.faqContainer + ' ' + classes.cursorPointer}
                    key={index + val.question}
                    style={{ background: openedFaqs[index] ? 'white' : '' }}
                    onClick={() => toggleFaq(index)}
                >
                    {openedFaqs[index] && <img src={dashIcon} className={`${classes.faqIcon} ${classes.hyphenIcon}`} />}
                    {!openedFaqs[index] && <img src={plusIcon} className={`${classes.faqIcon} ${classes.plusIcon}`} />}
                    <div className={classes.contentBox}>
                        <h5 className={openedFaqs[index] ? classes.questionBox : ''}>{val.question}</h5>
                        {
                            openedFaqs[index] &&
                            <div className={`${classes.answerContainer} ${scrollClass ? scrollClass : ''}`}>
                                <p className={classes.answer} >
                                    <span dangerouslySetInnerHTML={{ __html: val.answer }}></span>
                                </p>
                                {val.pointers &&
                                    <ol className={classes.answer}>
                                        {val.pointers.map((point, idx) => (<li key={point + idx} >{point}</li>))}
                                    </ol>
                                }
                            </div>
                        }
                    </div>
                </div>
            ))}
        </>
    )
}

export default FaqComponent;
import { makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Autoplay, Pagination } from "swiper";
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import { faWindowClose as closeIcon, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrowRightOrigin from '../../assets/track-icon/info-icons/arrow-right-with-origin.svg';
import { useState } from "react";
import { getPageSizeForSwiperSlides } from "../../utils/helper";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '95%',
    },
    imageSliderHeader: {
        textAlign: 'center',
        margin: 0,
    },
    cardsContainer: {
        width: '95%',
        margin: '1rem auto 0 auto',
        '& .swiper-pagination': {
            color: '#315CD5',
            marginBottom: '-10px'
        }
    },
    card: {
        padding: '2rem',
        maxWidth: '20rem',
        margin: '1rem auto 2rem auto',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        '& img': {
            width: '5rem',
        },
        '& span': {
            color: '#373737'
        },
        '& p': {
            color: '#373737'
        }
    },
    cardHeader: {
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        justifyContent: "center",
    },
    customScroll: {
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: '5px',
            background: '#707070',
        },
        '&:hover::-webkit-scrollbar-track': {
            borderRadius: '5px',
            boxShadow: 'inset 0 0 3px grey',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#8b8b8b',
        },
    },
    cardBody: {
        height: '180px',
        width: '100%',
        wordSpacing: '2px',
        marginTop: '1rem',
        lineHeight: '20px',
        letterSpacing: '0.5px',
        '& span': {
            marginRight: '0.3rem',
        },
    },
    cardFooter: {
        display: 'flex',
        gap: '1rem',
        marginTop: '1rem',
        '& img': {
            width: '5rem',
            height: '5rem',
            borderRadius: '50%',
        }
    },
    videoButton: {
        color: '#315CD5',
        display: 'inline-block',
        cursor: 'pointer',
        marginTop: '0.5rem',
        '& svg': {
            marginRight: '0.3rem',
            fontSize: '1.2rem',
        },
        '& b': {
            position: 'relative',
            top: '-1px',
        }
    },
    floatRight: {
        float: 'right'
    },
    descriptionDisplayer: {
        '& img': {
            width: "unset"
        }
    }
}))

const TestimonialsDisplayer = ({ data, className, title }) => {

    const classes = useStyles();
    const isSMScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isMDScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [videoUrlToShow, setVideoUrlToShow] = useState({});

    let sliderPaginationSize = getPageSizeForSwiperSlides(isSMScreen, isMDScreen, data?.length);

    const setVideos = (url, visible) => {
        setVideoUrlToShow(prev => ({ ...prev, [url]: visible }));
    }

    return (
        <section className={`${classes.root} ${className ? className : ''}`}>
            <h1 className={classes.imageSliderHeader}>{title}</h1>
            <div className={classes.cardsContainer}>
                <Swiper
                    slidesPerView={sliderPaginationSize}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    autoplay={false}
                // autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
                >
                    {
                        data.map((val, idx) => {
                            return (
                                <SwiperSlide key={idx + val.expertName}>
                                    <div className={classes.card}>
                                        <div className={classes.cardHeader}>
                                            {
                                                val.prevCompany && (
                                                    <>
                                                        <img src={val.prevCompany} />
                                                        <img src={arrowRightOrigin} style={{ height: "60%" }} />
                                                    </>
                                                )
                                            }
                                            <img src={val.currCompany} />
                                        </div>
                                        <div className={`${classes.cardBody} ${classes.customScroll}`}>
                                            {videoUrlToShow[val.videoPath] ?
                                                <>
                                                    <iframe
                                                        title="videoFrame"
                                                        width="100%"
                                                        height="100%"
                                                        src={val.videoPath}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        style={{ background: "#000" }} />
                                                </>
                                                : <>
                                                    <span className={`${classes.descriptionDisplayer}`} dangerouslySetInnerHTML={{ __html: val.body }} />
                                                </>}
                                        </div>
                                        {val.videoPath && (videoUrlToShow[val.videoPath] ?
                                            <div className={classes.videoButton} onClick={() => setVideos(val.videoPath, false)}>
                                                <FontAwesomeIcon icon={closeIcon} />
                                                <b>Close</b>
                                            </div>
                                            :
                                            <div className={classes.videoButton} onClick={() => setVideos(val.videoPath, true)}>
                                                <FontAwesomeIcon icon={faPlayCircle} />
                                                <b>WATCH</b>
                                            </div>)}
                                        <div className={classes.cardFooter}>
                                            <img src={val.clientLogo} />
                                            <div>
                                                <p>{val.expertName} </p>
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: val.expertDesignation }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section >
    )
}

export default TestimonialsDisplayer;
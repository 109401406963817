import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import interviewQuestions from '../../assets/Banner/interviewQuestions3.png';
import arrowRightUpIcon from "../../assets/track-icon/info-icons/arrow-up-right-white.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column-reverse',
            margin: '2rem 0 0 0',
            padding: '0 2rem',
        },
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '1rem',
        margin: '5rem 0 0 0',
        padding: '0 5rem',
        '& div': {
            flex: '1 1 0',
        }
    },
    responsiveImg: {
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        },
        maxWidth: '30rem'
    },
    mainContent: {
        '& h1': {
            fontSize: '2rem',
            margin: 0,
        },
        '& li': {
            margin: '0.5rem 0'
        },
        '& button': {
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
            textTransform: 'none',
            background: '#171717',
            padding: '1rem',
            borderRadius: '8px',
            color: '#FFF',
            height: '3rem',
            maxWidth: 'fit-content',
            '&:hover': {
                background: '#030303'
            },
            '& img': {
                width: '0.8rem',
                marginBottom: '-1px',
            }
        },
    }
}))

const InfoSection4 = ({ showTrackSelectionOption }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <img src={interviewQuestions} className={classes.responsiveImg} />
            </div>
            <div className={classes.mainContent}>
                <h1>1 to 1 coaching for optimal progress</h1>
                <ul>
                    <li>Both for specific skills and interview training.</li>
                    <li>Get detailed feedback: analysis, gap identification and mitigation.</li>
                    <li>Video recording.</li>
                </ul>
                <Button
                    aria-haspopup="true"
                    onClick={showTrackSelectionOption}
                >
                    <span>Join now&nbsp;&nbsp;</span>
                    <img src={arrowRightUpIcon} />
                </Button>
            </div>
        </div>
    )
}

export default InfoSection4;
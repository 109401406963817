import { Box, Button, Grid, makeStyles, MenuItem, Paper, Radio, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactGa from 'react-ga';
import { useLocation } from 'react-router-dom';
import SdeSvg from '../assets/Banner/SDE.svg';
import SdmSvg from '../assets/Banner/SDM.svg';
import TpmSvg from '../assets/Banner/TPM.svg';
import queryString from 'query-string'
import CalendlyModal from "./CalendlyModal"
import Autocomplete from '@material-ui/lab/Autocomplete';
import axiosInstance from '../utils/axiosInstance';
import { countries } from '../utils/Constants';
import AmaZonLogo from "../assets/logo/amazon_ColorFull_logo.png"
import LinkDinLogo from "../assets/logo/LinkDin_Vector.png";
import formCardImg from "../assets/Banner/rahul_salota.jpeg"
import { useUser } from '../context/UserProvider';

let userModel = {
    name: '',
    email: '',
    phoneCountry: 'UnitedStates',
    phoneNumber: '',
    category: ''
}

const sdeCardData = [{
    title: "SDE (Software Development Engineer)",
    name: "Rahul Salota",
    image: formCardImg,
    description: "21+ years of industry experience with established companies like Amazon and high-velocity startups like Zulily",
    firstLogo: AmaZonLogo,
    secoundLogo: LinkDinLogo,
    text: "Linkedin",
    linkDinLink: "https://www.linkedin.com/in/rsalota"
}]

const categories = [
    {
        catName: 'Software Development Engineer',
        icon: `${SdeSvg}`
    },
    {
        catName: 'Software Development Manager',
        icon: `${SdmSvg}`
    },
    {
        catName: 'Technical Program Manager',
        icon: `${TpmSvg}`
    },
    {
        catName: 'Data Engineer',
        icon: `${SdeSvg}`
    },
    {
        catName: 'Machine Learning Engineer',
        icon: `${SdmSvg}`
    },
    {
        catName: 'Solution Architect',
        icon: `${TpmSvg}`
    }
]

const useStyles = makeStyles((theme) => ({
    root: {
        border: props => props.border || 0,
        width: '50%',
        margin: props => props.margin || 0,
        backgroundColor: props => props.backgroundColor || theme.palette.primary.main,
        '& .MuiGrid-item': {
            // marginBottom: '0.375rem !important',
            marginLeft: '0 !important'
        },
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            zIndex: '5'
        },
        '& .MuiGrid-root.MuiGrid-item': {
            marginBottom: "auto !important",
        }
    },
    heading: {
        padding: "14px 0px 0.5rem !important",
        fontWeight: 600,
        lineHeight: '37px'
    },
    form: {
        padding: '0 1.5rem 1.5rem'
    },
    fullWidth: {
        width: "100%",
    },
    formFields: {
        width: '100%',
        height: '3rem',
        '& .MuiFormHelperText-root': {
            color: '#fff',
            margin: '0 !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'white',
            transition: 'none',
            borderStyle: 'none'
        },
        '& .MuiInputBase-input': {
            zIndex: 1,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12.8px',
            lineHeight: '19px'
        },
        '& .MuiInputBase-root': {
            height: '3rem'
        },
        '& .MuiSvgIcon-root': {
            width: '35px !important',
            height: '100% !important',
            fontSize: '1rem'
        },
        '& .MuiSelect-icon': {
            top: 0,
            zIndex: 1,
            color: '#A8A8A8'
        }
    },
    logo: {
        paddingRight: '3px'
    },
    contentBox: {
        boxSizing: 'content-box',
        '& .MuiIconButton-root': {
            zIndex: "1000",
            marginTop: "-3px",
        }
    },
    radioWrapper: {
        width: '30%',
        textAlign: 'center',
        background: 'white',
        borderRadius: '4px',
        '& .MuiTypography-root': {
            fontSize: '0.5rem'
        },

    },
    submitBtn: {
        backgroundColor: '#E25252',
        borderRadius: '4px',
        boxShadow: 'none',
        height: 'auto',
        marginTop: '18px',
        width: '100%',
        fontWeight: '600',
        fontSize: '18px',
        letterSpacing: '1px',
        '&:hover': {
            background: '#D73C3C'
        },
        '&:active': {
            background: '#D33030',
            boxShadow: 'none'
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            fontSize: "14px !important",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "10px",
        },
    },
    FormCardBg: {
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        borderRadius: "5px",
    },
    cardHeader: {
        padding: "3px",
        borderRadius: "5px 5px 0px 0px",
        background: "#EFEFF6",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    headerTitle: {
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: "15px",
        lineHeight: "20px",
    },
    cardBody: {
        padding: "9px 13px",
        display: "flex",
        alignItems: "center",

    },
    cardUser_img: {
        borderRadius: "50%",
        width: "100%",
        margin: "10px"
    },
    cardBody_container: {
        marginLeft: "18px",
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
        },
        '& .cardName': {
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "17px",
            display: "block",
        },
        '& .cardDescription': {
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#858484",
        }
    },
    cardLeftSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 38px",
        width: "100%",
        alignItems: "end",
        background: "aliceblue",
        borderRadius: "0px 0px 5px 5px",
        '& .cardFooter_text': {
            color: "#0078b8",
            fontSize: "15px",
            fontFamily: "Poppins",
            fontWeight: "600",
            marginLeft: "10px",
        },
        '& .linkDinLink': {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            textDecoration: "none",
        },
        '& .amazoneLogo': {
            width: "65px"
        },
        '& .linkDinLogo': {
            width: "20px"
        }
    },
    marginTopInputs: {
        marginTop: '0.5rem',
    }
}
));

export default function UserFormForConsultation({
    focusName,
    resetFocusName,
    email,
    setEmail,
    styleProps,
    fullWidth,
    calendlyPrefillData,
    setFormData
}) {
    const focusNameElement = useRef(null);
    const classes = useStyles(styleProps || {});
    const [user, setUser] = useState({ ...userModel, email });
    const [errors, setErrors] = useState(userModel);
    const [message, setMessage] = useState(null);
    const { search } = useLocation();
    const { consultation } = useUser();

    useEffect(() => {
        if (focusName) {
            focusNameElement.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            let inputElement = focusNameElement.current.getElementsByTagName('input')[0];
            inputElement.classList.add("transition-with-shadow");
            setTimeout(() => {
                inputElement.click();
                inputElement.classList.remove("transition-with-shadow");
            }, 1000);
            resetFocusName();
        }
    }, [focusName]); // eslint-disable-line

    const focusElement = (ev) => {
        ev.target.focus();
    }

    const handleInputChange = ev => {
        const name = ev.target.name;
        const value = ev.target.value;
        if ((name === "name" && value && !(/^[a-zA-Z ]+$/.test(value)))
            || (name === "phoneNumber" && value && isNaN(value))) {
            return;
        }
        let newUserInfo = {
            ...user,
            [name]: value
        };
        setUser(newUserInfo);
        validateForm(newUserInfo, name);
    }

    const handleSelectTrackChange = (name, value) => {
        let newUserInfo = {
            ...user,
            [name]: value
        };
        setUser(newUserInfo);
        validateForm(newUserInfo, name);
    }

    const validateForm = (user, fieldName) => {
        let errorForm = {};
        if (!fieldName || fieldName === 'name') {
            errorForm.name = user.name ? "" : "Name is required";
        }
        if (!fieldName || fieldName === 'email') {
            errorForm.email = user.email?.length > 0 ? /\S+@\S+\.\S+/.test(user.email) ?
                "" : "Email is not valid" : "Email is required";
        }
        if (!fieldName || fieldName === 'phoneNumber') {
            errorForm.phoneNumber = !user.phoneNumber ? "Phone Number is required" : /^([+]\d{2})?\d{10}$/.test(user.phoneNumber) ?
                "" : "Mobile number is not valid";
        }
        if (!fieldName || fieldName === 'category') {
            errorForm.category = user.category ? "" : "Track is required";
        }
        setErrors(prev => ({
            ...prev,
            ...errorForm
        }));

        return Object.values(errorForm).every(x => !x);
    }

    const preparePayload = () => {
        let payload = {};
        const nameParts = user.name.split(' ');
        payload.first_name = nameParts[0];
        payload.email = user.email;
        const extras = queryString.parse(search);
        payload = { ...payload, extras };
        if (nameParts?.length > 1) payload.last_name = nameParts[1];
        const phone = getFormattedPhoneNumber();
        if (phone !== '') payload.phone = phone;
        payload.tags = ["ClickedFreeConsultation", user.category];
        return payload;
    }

    const getFormattedPhoneNumber = () => {
        if (user.phoneCountry !== '' && user.phoneNumber !== '') {
            return '+' + countries.find(x => x.value === user.phoneCountry).label + ' ' + user.phoneNumber;
        }
        return '';
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm(user)) {
            ReactGa.event({
                category: 'Button',
                action: 'User pressed register for a free session button on homepage'
            });
            const payload = preparePayload();

            setFormData({ name: user.name, email: user.email });
            setEmail(payload.email);
            window.open(
                `${consultation.consultationUrl}?email=${user.email}&name=${user.name}`,
                '_blank'
            );
            axiosInstance.post("users", payload)
        }
    }

    const getMenuItem = (option) => {
        return (
            <MenuItem key={option.value} value={option.value}>
                <Box display="flex" alignItems="center" id="countryDropdown">
                    <img className={classes.logo} src={option.icon} alt={option.value} width="32px"></img>
                    <Typography variant="caption" >{'+' + option.label}</Typography>
                </Box>
            </MenuItem>
        )
    }

    const setStatusMessage = (message) => {
        setMessage(message);
        const removeMessage = () => setMessage(null);
        setTimeout(removeMessage, 4000);
    }

    return (
        <Paper className={clsx(classes.root, fullWidth ? classes.fullWidth : "")}>
            <Typography variant="h5" align="center" color="secondary" className={classes.heading}>
                Connect with a Coach
            </Typography>
            <form className={classes.form} autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} ref={focusNameElement}>
                        <TextField
                            autoFocus
                            variant="outlined"
                            value={user.name}
                            name="name"
                            onChange={handleInputChange}
                            placeholder="Name*"
                            className={classes.formFields + " " + classes.marginTopInputs}
                            error={errors.name?.length > 0}
                            helperText={errors.name}
                            onClick={focusElement}
                            style={{ marginBottom: errors.name?.length > 0 ? '1rem' : '' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={user.email}
                            className={classes.formFields + " " + classes.marginTopInputs}
                            name="email"
                            type="email"
                            onChange={handleInputChange}
                            placeholder="Email address*"
                            required
                            error={errors.email?.length > 0}
                            helperText={errors.email}
                            style={{ marginBottom: errors.email?.length > 0 ? '1rem' : '' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    select
                                    value={user.phoneCountry}
                                    onChange={handleInputChange}
                                    fullWidth
                                    className={classes.formFields + " " + classes.marginTopInputs}
                                    name="phoneCountry"
                                >
                                    {countries.map((option) => (
                                        getMenuItem(option)
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    value={user.phoneNumber}
                                    className={classes.formFields + " " + classes.marginTopInputs}
                                    name="phoneNumber"
                                    onChange={handleInputChange}
                                    placeholder="Phone*"
                                    error={errors.phoneNumber?.length > 0}
                                    helperText={errors.phoneNumber}
                                    style={{ marginBottom: errors.phoneNumber?.length > 0 ? '1rem' : '' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justify="space-between" className={classes.contentBox}>
                        <Autocomplete
                            id="combo-box-demo"
                            disableClearable
                            style={{ width: "100%", marginBottom: errors.category?.length > 0 ? '1rem' : '' }}
                            options={categories.map((option) => option.catName)}
                            name="category"
                            value={user?.category}
                            onChange={(e, value) => handleSelectTrackChange('category', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="category"
                                    value={user?.category}
                                    onChange={(e, value) => handleSelectTrackChange('category', value)}
                                    placeholder="Select track*"
                                    variant="outlined"
                                    className={classes.formFields + " " + classes.marginTopInputs}
                                    error={errors.category?.length > 0}
                                    helperText={errors.category}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid>
                        <Grid className={classes.FormCardBg}>
                            {
                                sdeCardData?.map((ele) => (
                                    <div>
                                        {/* <Grid className={classes.cardHeader}>
                                            <p className={classes.headerTitle}>{ele.title}</p>
                                        </Grid> */}
                                        <Grid className={classes.cardBody}>
                                            <Grid lg={3} md={3} sx={12} className={classes.cardLeftSide}>
                                                <img src={ele?.image} alt="user_img" className={classes.cardUser_img} />
                                            </Grid>
                                            <Grid lg={9} md={9} sx={12} className={classes.cardBody_container}>
                                                <span className="cardName">{ele?.name}</span>
                                                <span className='cardDescription'>{ele?.description}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.cardFooter}>
                                            <img src={ele.firstLogo} alt="amazonLogo" className="amazoneLogo" />
                                            <a href={ele?.linkDinLink} target="_blank" className="linkDinLink">
                                                <img src={ele.secoundLogo} alt="LinkDinLogo" className="linkDinLogo" />
                                                <span className="cardFooter_text">{ele.text}</span>
                                            </a>
                                        </Grid>
                                    </div>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            component="button"
                            variant="contained"
                            classes={{ root: classes.submitBtn }}
                            disableRipple
                        >
                            Discuss your plan with Rahul Salota
                        </Button>
                        {message && <Typography variant="subtitle2" color="secondary">
                            {message}
                        </Typography>}
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

import { Button, makeStyles } from '@material-ui/core';
import backgroundImage from '../../assets/coverBackground/backgroudPoster.png';
import arrowRightUpIcon from "../../assets/track-icon/info-icons/arrow-up-right-white.svg";
import React from 'react';
import { useUser } from '../../context/UserProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            margin: '2rem auto 0 auto',
            padding: '3rem 2rem',
        },
        textAlign: 'center',
        margin: '5rem auto 0 auto',
        padding: '3rem 5rem',
        width: '95%',
        backgroundSize: 'contain',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '20px'
    },
    heading: {
        color: '#FFF',
    },
    subHeading: {
        color: '#FFB8C0',
    },
    styledButton: {
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        textTransform: 'none',
        background: '#171717',
        padding: '1rem',
        borderRadius: '8px',
        color: '#FFF',
        height: '3rem',
        maxWidth: 'fit-content',
        '&:hover': {
            background: '#030303'
        },
        '& img': {
            width: '0.8rem',
            marginBottom: '-1px',
        }
    }
}))

const InfoSection6 = ({ setFocusOfUserForm }) => {

    const classes = useStyles();
    const { consultation } = useUser();

    return (
        <div className={classes.root} style={{ backgroundImage: "url(" + backgroundImage + ")" }}>
            <h1 className={classes.heading}>Dreaming of a job at a FAANG company <br />but but don't know where to start?</h1>
            <h2 className={classes.subHeading}>Let the experts guide you. </h2>
            <Button className={classes.styledButton} onClick={setFocusOfUserForm}>
                <span>{consultation.consultationButtonName}&nbsp;&nbsp;</span>
                <img src={arrowRightUpIcon} />
            </Button>
        </div>
    )
}

export default InfoSection6;
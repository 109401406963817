import { Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import FAQs from './Faq';
import Header from './Header';
import InfoSection1 from './InfoSection1';
import InfoSection2 from './InfoSection2';
import InfoSection3 from './InfoSection3';
import InfoSection4 from './InfoSection4';
import InfoSection5 from './InfoSection5';
import InfoSection6 from './InfoSection6';
import Testimonial from './Testimonial';


const Home = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const tracks = [
        {
            href: "/track/technical-program-manager",
            title: 'Technical Program Manager'
        },
        {
            href: "/track/software-engineering-manager",
            title: 'Software Development Manager'
        },
        {
            href: "/track/software-development-engineer",
            title: 'Software Development Engineer'
        },
        {
            href: "/track/system-design",
            title: 'System Design'
        },
        {
            href: "/track/amazon-behavior-interview",
            title: 'Amazon Behavior Interview'
        },
    ]

    const showTrackSelectionOption = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeTrackSelectionOption = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Header {...props} />
            <InfoSection1 />
            <InfoSection2 showTrackSelectionOption={showTrackSelectionOption} />
            <InfoSection3 />
            <InfoSection4 showTrackSelectionOption={showTrackSelectionOption} />
            <InfoSection5 />
            <InfoSection6 {...props} />
            <Testimonial />
            <FAQs />

            {/* Tracks selection menu */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeTrackSelectionOption}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem disabled={true} >Choose One Track</MenuItem>
                {tracks.map((val, idx) => {
                    return (
                        <MenuItem
                            component={Link}
                            onClick={closeTrackSelectionOption}
                            key={idx + val.title}
                            to={val.href}
                        >
                            {val.title}
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

export default Home;